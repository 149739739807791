<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card layout-toppanel">
        <p><i  class="pi pi-fw pi-info-circle"></i> Se gestiona todos los obras para la gestión de cartas fianzas, de todas las sedes.</p>
      </div>
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="openNew()"
            />
          </template>

          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          selectionMode="single"
          dataKey="id"
          sortField="orden"
          :sortOrder="1"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} items"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'id',
            'nombre',
            'contratista.nombre'
           
          ]"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column selectionMode="single" headerStyle="width: 3rem"></Column>
          <Column field="id" header="Id" :sortable="true" headerStyle="width: 3rem">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              <b>{{ slotProps.data.id }}</b>
            </template>
          </Column>
          <Column
            field="nombre"
            header="Nombre"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{slotProps.data.nombre}}
            </template>
          </Column>
          <Column
            field="contratista.nombre"
            header="Contratista"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Contratista</span>
              {{slotProps.data.contratista.nombre}}
            </template>
          </Column>
          <Column headerStyle="width: 3rem">
            <template #body="slotProps">
              <Button
                icon="pi pi-ellipsis-v"
                @click="[itemClick(slotProps.data), toggle($event)]"
              />
              <Menu ref="menu" :model="items" :popup="true">
                <template #item="{ item }">
                  <Menuitem :rel="slotProps">
                    <li>{{ item.label }}</li>
                  </Menuitem>
                </template>
              </Menu>
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '750px' }"
          header="Detalle de la obra"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="name">Nombre o denominación</label>
             <Textarea
                v-model="product.nombre"
                required="true"
                rows="3"
                cols="20"
                autofocus
              :class="{ 'p-invalid': submitted && !product.nombre }"
              />
            <small class="p-invalid" v-if="submitted && !product.nombre"
              >Nombre o denominación es requerido.</small
            >
          </div>
          <div class="p-field">
            <label for="name">Contratista</label>
            <Dropdown
                  v-model="product.contratista"
                  required="true"
                  :options="contratista"
                  :filter="true"
                  optionLabel="nombre"
                  :disabled="isBussy"
                  placeholder="Seleccione Contratista"
                  :class="{ 'p-invalid': submitted && !product.contratista }"
                  filterPlaceholder="Busque el banco"
                />
                <small class="p-invalid" v-if="submitted && !product.contratista"
                  >Seleccione Contratista.</small
                >
          </div>
          <div class="p-field">
             <label for="telefono">Fecha Inicio de la Obra</label>
          <Calendar
                :showIcon="true"
                :showButtonBar="true"
                v-model="product.fechaInicio"
                dateFormat="dd/mm/yy"
                :class="{ 'p-invalid': submitted && !product.fechaInicio }"
              ></Calendar>
               <small
                class="p-invalid"
                v-if="submitted && !product.fechaInicio"
                >Ingrese fecha de Inicio de la carta.</small
              >
          </div>
          <div class="p-field">
            <label for="telefono">Lugar</label>
            <InputText
              id="lugar"
              v-model.trim="product.lugar"
            />
          </div>
          <div class="p-field">
            <label for="observacion">Observación</label>
            <InputText
              id="observacion"
              v-model.trim="product.observacion"
            />
          </div>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmar"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="selectedProducts"
              >Está seguro de querer borrar el item:
              <b>{{ selectedProducts.id }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Si"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {

  data() {
    return {
      contratista : null,
      errors: [],
      scale: "page-width",
      items: [
        {
          label: "Opciones",
          items: [
           
            {
              label: "Editar",
              icon: "pi pi-fw pi-pencil",
              command: () => {
                this.editProduct(this.selectedProducts);
              },
            },
 
            {
              label: "Borrar",
              icon: "pi pi-fw pi-trash",
              command: () => {
                this.confirmDeleteProduct()
              },
            },
          ],
        },
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      product: {},
      selectedProducts: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombre: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      isBusy: false,
      submitted: false,
      action: "gsfianza/OBRA_SAVE",
    };
  },
  
  methods: {
    itemClick(data) {
      this.selectedProducts = data;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
   
    saveProduct() {
      this.submitted = true;
      let modelo = this.product;
      
       if (this.product.nombre  && this.product.fechaInicio && this.product.contratista) {
          let fechaI = new Date(this.product.fechaInicio);
          let fechaII =  fechaI.getDate() +  "/" +  (fechaI.getMonth() + 1) +   "/" +  fechaI.getFullYear();
          modelo.fechaInicio = fechaII;
         
          this.isBusy = true;
          this.$swal({
          width: 370,
          text: "Cargando...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch(this.action, modelo)
              .then((res) => {
                this.productDialog = false;
                this.isBusy = false;
                this.$swal.close();
                this.listarObra();
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Obra Actualizado id: " + res.nombre,
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$swal.close();
                this.isBusy = false;
                this.error(error)
              });
          }}).then((result) => {
          console.log(result);
        });
        }
    },
    listarObra() {
      let params = {
        ejecutora: this.product.ejecutora
      };
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsfianza/OBRA_LIST", params)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = res === undefined ? [] : res;
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    listarContratista(edit) {
      let params = {
        ejecutora: this.user.ejecutora
      };
      this.isBusy = true;
      this.$swal({
        width: 370,
        text: "Cargando....",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsfianza/CONTRATISTA_LIST", params)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.contratista = res === undefined ? [] : res;
              if(edit) {
                let contratista = this.product.contratista;
                let scontratista =null;
                res.forEach(function(element) {
                  if(contratista.id == element.id){
                      scontratista = element;
                  }
                });
                this.product.contratista = scontratista;
              } else {
                this.product.contratista = null;
              }
     
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    error(evt) {
      if (evt.response.status == 401 || evt.response.status == 500) {
        this.$swal(
          evt.response.data.errorMessage,
          "Ud. no tiene permitido esta función",
          "warning"
        );
      }
    },
    openNew() {
      this.action = "gsfianza/OBRA_SAVE"
      this.product = {
        id: 0,
        nombre: "",
        ejecutora: this.user.ejecutora,
        estado: true
      };
      this.submitted = false;
      this.productDialog = true;
      if(this.contratista==null){
        this.listarContratista(false);
      }
      
     
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
   
  
   
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.product = this.selectedProducts;
      this.deleteProductDialog = false;
      this.isBusy = true;

      this.$swal({
        width: 370,
        text: "Borrando...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsfianza/OBRA_DELETE", this.product.id)
            .then((res) => {
              this.isBusy = false;
              this.$swal.close();
              this.products = this.products.filter(
                (val) => val.id !== this.product.id
              );
              this.product = {};
              //this.listarData();
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Obra borrado, nombre: " + res.nombre,
                life: 3000,
              });
            })
            .catch((error) => {
              this.$swal.close();
              this.isBusy = false;
              this.error(error);
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    editProduct(product) {
      this.action = "gsfianza/OBRA_UPDATE";
      this.product = { ...product };
      this.productDialog = true;
      if(this.contratista==null){
        this.listarContratista(true)
      }
      this.product.fechaInicio = new Date(
        parseInt(this.product.fechaInicio.substring(6, 10)),
        parseInt(this.product.fechaInicio.substring(3, 5)) - 1,
        parseInt(this.product.fechaInicio.substring(0, 2))
      );
    },
  },
  computed: {
    ...mapGetters({
      obra: "gsfianza/getModelObra",
      user: "gsdialogoauth/getUser",
    }),
  },

  mounted() {
    if (this.obra.estado == false) {
      this.$router.push({
        name: "fianza/obra",
      });
    }
    this.listarObra();

  }
};
</script>


